var isPage = "";
var common = {
	loadFlag : true,
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		scrl.init();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		$("a#btn_menu").off().on("click", function(){
			if(!$html.classList.contains("nav")){
				$html.classList.add("nav");
				setTimeout(function(){
					$html.classList.add("show_nav");
				},50);
			}else{
				$html.classList.remove("show_nav");
				setTimeout(function(){
					$html.classList.remove("nav");
				},50);
			}
		});
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}

		$("#detail_inr").css({
			"width": winW + "px"
		});
		
		if(isSmallScreen){
			$("#global_header").css({
				"width" : ""
			});
		}else{
			var w = 0;
			if($html.classList.contains("detail")){
				w = $("#detail_wrap").width();
			}else{
				w = $("body").width();
			}
			$("#global_header").css({
				"width" : w + "px"
			});
		}
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (id,callback) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : "0px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height(),
			docH = $(document).height();
		if(btmD >= docH - 100){
			if(common.loadFlag){
				common.loadFlag = false;
				scrl.getNext();
			}
		}

	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});