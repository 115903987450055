var scrl = {
	current	: 0,
	max 	: 0,
	per 	: 10,
	data 	: [],
	isFirst : true,
	init: function(){
		var url = "/api/?page=" + scrl.current;
		this.getJson(url).done(function(data){
			scrl.data = data;
			scrl.max = Math.ceil(data.total_count/scrl.per);
			common.resize();
			if(scrl.isFirst){
				page.init();
				common.nav();
			}
			scrl.makeHTML();
		});
	},
	getJson : function(url){
		var defer = $.Deferred();
		$.ajax({
			type: "GET",
			dataType: 'json',
			url: url,
			success : defer.resolve,
			error: defer.reject
		});
		return defer.promise();
	},
	getNext: function(){
		scrl.current++;
		if(scrl.current > scrl.max){
			scrl.current = scrl.max-1;
			return false;
		}else{
			console.log("getNext:", scrl.current);
			scrl.init();
		}
	},
	makeHTML: function(){
		console.log(scrl.data, scrl.current, scrl.max);
		let HTML 	= '';
		let start 	= 0;
		let end 	= start + scrl.per;
		let allEnd 	= scrl.data.total_count - scrl.per*scrl.current;
		console.log(start,end, allEnd)
		
		if(end > allEnd){
			end = allEnd;
		}
		console.log(start, end)

		var data = scrl.data.posts;
		for(var i = start; i < end; i++){
			var d = data[i];

			HTML 	+= '<li>'
					+ '<a href="' + d.link + '" title="' + d.title + '">'
					+ '<figure style="background-image: url(' + d.eye_catch + ');"></figure>'
					+ '<div class="ttl_work">'
					+ '<div class="ttl_work_inr">'
					+ '<h3><span>' + d.title + '</span></h3>'
					+ '</div>'
					+ '</div>'
					+ '</a>'
					+ '</li>';
		}
		$(".list_work").append(HTML);
		page.nav();
		setTimeout(function(){
			common.loadFlag = true;
		},2000);
	}
}
