/*
HOW TO
MAKE IMAGE ARRAY AND PASS TO preload.startLoad
------------------------------------------------
var imgarry = [];
for(var i = 0; i < 100; i++){
	imgarry.push("images/test/bg" + i + ".png");
}
preload.startLoad(100,imgarry).done(function(){
	console.log("all done")
});
*/

var preload = {
	count: 0,
	max : 0,
	arry: [],
	startLoad: function(max,arry){
		var defer = $.Deferred();
		preload.count = 0;
		this.max = max;
		this.arry = arry;
		var promise = this.load();

		promise.done(function(){
			defer.resolve();
		});
		return defer.promise();
	},
	load: function(){
		var defer 	= $.Deferred();
		loadImg();

		function loadImg(){
			var newImg = new Image();
			newImg.src = preload.arry[preload.count];
			newImg.onload = function(){
				preload.count++;
				var per = Math.floor(preload.count/preload.max*100);
				$("#loading p").css({
					"width" : per + "%"
				});
				if(preload.count < preload.max){
					loadImg();
				}else{
					defer.resolve();
				}
			}
		}
		return defer.promise();
	}
}