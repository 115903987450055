var page = {
	current: "",
	folder : "",
	isFirst: true,
	canClick: true,
	init: function(){
		page.checkURL();
		page.nav();
		common.nav();

		$(document).off("scroll").on("scroll", function(){
			common.scrl();
		});
	},
	nav: function(){
		let btns = "#btn_close, .list_work li a, a#next_project, a.home, #glbnav li:eq(0) a";
		$(btns).off().on("click", function(e){
			e.preventDefault();
			let path = $(this).attr("href");
			change(path);
		});
		function change(path){
			if(page.canClick){
				page.canClick = false;
				page.changeURL(path);
			}
		}
	},
	changeURL: function(path){
		window.history.pushState(null, null, path);
		page.checkURL();
	},
	showLoading: function(){
		var defer = $.Deferred();
		let $body = $("body");
		$html.classList.add("loading");
		setTimeout(function(){
			$html.classList.add("show_loading");
			setTimeout(function(){
				defer.resolve();
			},600);
		},50);
		return defer.promise();
	},
	closeLoading: function(){
		var defer = $.Deferred();
		let $body = $("body");
		$html.classList.add("close_loading");
		$("#loading p.l0").css({
			"width" : "0px"
		});
		$("#loading p.l1").css({
			"width" : "0px"
		});
		setTimeout(function(){
			$html.classList.remove("show_loading");
			setTimeout(function(){
				$html.classList.remove("loading");
				$html.classList.remove("close_loading");
			}, 600);
			defer.resolve();
		},600);
		return defer.promise();
	},
	loadContent: function(){
		var loadURL = page.current,
			$body 	= $("body");
		console.log(page.folder);
		if(page.folder === ""){
			var listLen = $(".list_work li").length;
			if(listLen != 0){
				page.showHome();
			}else{
				page.showLoading().done(function(){
					$.get(loadURL,{},function(data){
						page.addWorksList(data);
					});
				});
			}
		}else{
			//すでにWORKSのページの場合は分岐させる
			if($html.classList.contains("detail")){
				$html.classList.add("change_detail");
				setTimeout(function(){
					$.get(loadURL,{},function(data){
						page.changeHTMLClass();
						page.changeContent(data);
					});
				},600);
			}else{
				page.showLoading().done(function(){
					setTimeout(function(){
						if(page.folder === "works"){
							$.get(loadURL,{},function(data){
								if($body.hasClass("nav")){
									$body.removeClass("nav");
								}
								//window.scrollTo(0,0);
								page.changeHTMLClass();
								page.changeContent(data);
							});
						}else{
							var listLen = $(".list_work li").length;
							if(listLen === 0){
								$.get(loadURL,{},function(data){
									page.addWorksList(data);
								});
							}
						}
					},300);
				});
			}
		}
	},
	changeContent: function(data){
		//console.log(data);
		var out_html 	= $(data),
			ttl 		= out_html.filter("title")[0].innerHTML,
			$content 	= $("#detail #detail_wrap"),
			content 	= $("#load_area", out_html)[0];
		document.title = ttl;
		//console.log(content);
		$content.html(content);
		var imgArry = [];
		$("#content img").each(function(){
			imgArry.push($(this).attr("src"));
		});
		if(imgArry.length > 0){
			preload.startLoad(imgArry.length,imgArry).done(function(){
				afterLoadImg();
			});
		}else{
			afterLoadImg();
		}

		function afterLoadImg(){
			$("#detail_inr").animate({
				scrollTop : "0px"
			},{
				"duration" 	: 10
			});
			if($html.classList.contains("change_detail")){
				setTimeout(function(){
					$html.classList.remove("change_detail");
					page.afterLoadPage();
				},600);
			}else{
				setTimeout(function(){
					page.closeLoading().done(function(){
						page.afterLoadPage();
						setTimeout(function(){
							$("#loading p.p0").css({
								"width" : ""
							});
							$("#loading p.p1").css({
								"width" : ""
							});
						},1000);
					});
				}, 800);
			}
		}
	},
	addWorksList: function(data){
		var defer = $.Deferred();
		//console.log(data);
		var out_html 	= $(data),
			ttl 		= out_html.filter("title")[0].innerHTML,
			$content 	= $("#works"),
			content 	= $("#works ul.list_work", out_html)[0];
		document.title = ttl;
		$content.html(content);
		var imgArry = [];
		$("#works ul.list_work li").each(function(){
			var img = $(this).find("figure").css("background-image").replace('url("', '');
			img = img.replace('")', '');
			imgArry.push(img);
		});
		preload.startLoad(imgArry.length,imgArry).done(function(){
			page.showHome();
			afterLoadImg();
		});
		function afterLoadImg(){
			setTimeout(function(){
				page.afterLoadPage();
				setTimeout(function(){

					page.closeLoading().done(function(){
						defer.resolve();
					});
				}, 200);
			}, 600);
		}
		return defer.promise();
	},
	afterLoadPage: function(){
		if(page.folder === "works"){
			if(!$html.classList.contains("detail")){
				$html.classList.add("detail");
				setTimeout(function(){
					movie.init();
					$html.classList.add("show_detail");
				},600);
			}
		}else if(page.folder === ""){
			
		}
		page.nav();
		common.nav();
		
		page.canClick = true;
	},
	checkURL: function(){
		let url 	= location.href;
		let prop 	= location.pathname;
		let $body 	= $("body"),
			folders = prop.split("/"),
			arg 	= new Object,
			pair 	= location.search.substring(1).split('&'),
			isModal	= false,
			folderLength 	= folders.length-2,
			folderName 		= folders[1];
		console.log(folders, folderLength, folderName);
		if(folderName != ""){
			//トップ以外
			if(folderName === "works"){
				page.folder 	= folderName
				page.current = location.pathname;
				if(page.isFirst){
					page.isFirst = false;
					page.afterLoadPage();
				}else{
					page.loadContent();
				}
			}else if(folderName === "about"){
				if(page.isFirst){
					page.isFirst = false;
				}
				page.openAbout();
			}
		}else{
			//トップ
			page.current 	= "/";
			page.folder 	= "";
			if(!page.isFirst){
				if($html.classList.contains("detail")){
					page.loadContent();
				}
				if($html.classList.contains("about")){
					page.showHome();
				}
			}else{
				page.isFirst = false;
			}
		}
	},
	changeHTMLClass: function(){
		if(page.isPage === "home"){
			$html.classList.remove("page");
			$html.classList.add("index");
		}else{
			if($html.classList.contains("index")){
				$html.classList.remove("index");
				$html.classList.add("page");
			}
		}
	},
	showHome: function(){
		if($html.classList.contains("detail")){
			$html.classList.add("close_detail");
			$html.classList.remove("show_detail");
			setTimeout(function(){
				$html.classList.remove("detail");
				setTimeout(function(){
					$html.classList.remove("close_detail");
					page.nav();
					common.nav();
					page.canClick = true;
				},100);
			},600);
		}
		if($html.classList.contains("about")){
			page.closeAbout();
		}
	},
	openAbout: function(){
		if(!$html.classList.contains("about")){
			$html.classList.add("about");
			$html.classList.remove("show_nav");
			setTimeout(function(){
				$html.classList.remove("nav");
				$html.classList.add("show_about");
				page.afterLoadPage();
				page.canClick = true;
				page.nav();
				common.nav();
			},600);
		}
	},
	closeAbout: function(){
		console.log("closeabout")
		if($html.classList.contains("about")){
			$html.classList.remove("show_about");
			setTimeout(function(){
				$html.classList.remove("about");
				page.afterLoadPage();
			},600);
		}
	}
}