var ytFlag 	= 0;
var YT,ytPlayer,movieTimer,spPlayer;
var players = [];

function onYouTubeIframeAPIReady() {
	
}
var Video = {
	isPlaying: false,
	movieID: "",
	player: ""
}
var videos = [];
var movie = {
	init: function(){
		videos = [];
		var count = 0;
		var max = $(".movie").length;
		if(max > 0){
			countup();
		}

		function countup(){
			var $movie = $(".movie").eq(count);
			var _m = object(Video);
			_m.isPlaying 	= false;
			_m.movieID 		= $movie.attr("data-id");
			let playerID 	= $movie.find(".player").attr("id");
			var player = new YT.Player(playerID, {
				videoId 	: _m.movieID,
				playerVars 	: {
					autohide	: 1,
					controls	: 0,
					frameborder : 0,
					rel			: 0,
					start		: 0,
					showinfo 	: 0,
					disablekb	: 1,
					wmode		: "transparent",
					autoplay	: 0,
					mute 		: 1,
					playsinline	: 1,
					enablejsapi	: 1,
					iv_load_policy: 3,
					origin: location.protocol + '//' + location.hostname + "/"
				},
				events : {
					onReady: function(e){
						_m.player = e.target;
						videos.push(_m);
						count++;
						if(count <= max-1){
							countup();
						}else{
							movie.nav();
						}
					},
					onStateChange: function(e){
						var state = e.target.getPlayerState();
						if(state === 2){
							for(var i = 0; i < videos.length; i++){
								var $thisBlock = $(".movie").eq(i).find("a");
								if($thisBlock.hasClass("hide")){
									$thisBlock.removeClass("hide");
									setTimeout(function(){
										$thisBlock.removeClass("playing");
									},600);
								}
							}
						}
					},
					onError: function(e){
						var errorstatus = e.data;
					}
				}
			});
		}
	},
	nav: function(){
		let $body = $("body");
		$(".movie a").off().on("click", function(){
			var $this = $(this);
			var idx = $(".movie").index($this.parent());
			if(!$(this).hasClass("playing")){
				$this.addClass("playing");
				setTimeout(function(){
					$this.addClass("hide");
				},600);
				console.log(videos[idx])
				for(var i = 0; i < videos.length; i++){
					if(i != idx){
						videos[idx].player.pauseVideo();
					}
				}
				videos[idx].player.playVideo();
			}
		});
	},
	setVolume: function(vol){
		ytPlayer.setVolume(vol);
	},
	seekAndPlay: function(time){
		
	}
}